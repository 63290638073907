import {Component, OnInit, OnDestroy} from '@angular/core';
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {AuthenticationService, UserDataEntity} from '@modules/authentication';
import {ModelSchema, Structures} from 'octopus-model';
import {
    brand,
    brandLogoSvg,
    modulesSettings,
} from '../../../../settings';
import {Roles} from 'shared/models/roles';
import {LegacyFloatLabelType as FloatLabelType} from '@angular/material/legacy-form-field';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {currentTimestamp} from 'shared/utils/datetime';
import {takeUntil} from 'rxjs/operators';

const settingsAuthStructure: ModelSchema = new ModelSchema({
    activeChangePasswordStrategy: Structures.boolean(false),
    askForHelp: Structures.boolean(false),
    displayLoginLogo: Structures.boolean(false),
    enableFormLogin: Structures.boolean(true),
    enableGAR: Structures.boolean(false),
    enableSSO: Structures.boolean(false),
    urlSSO: Structures.object(),
    validateEmailStrategyActivated: Structures.boolean(false),
});

export interface AuthenticationServiceSettings {
    firstConnexionRedirection: { [key in Roles | 'default']: string };
    floatLabelControl: FloatLabelType;
    isRegisterLinkTop: boolean;
    overrideDefaultRouteByRole: { [key in Roles | 'default']: string };
    selfSignup: boolean;
    redirectSignup: { url: string, target: string };
    signup: boolean;

}

export interface LoginPageSettings {
    activeChangePasswordStrategy: boolean;
    askForHelp: boolean;
    displayLoginLogo: boolean;
    enableFormLogin: boolean;
    enableGAR: boolean;
    enableSSO: boolean;
    urlSSO: { [lang: string]: string };
    validateEmailStrategyActivated: boolean;
}

@Component({
    selector: 'app-password-renew',
    templateUrl: './password-renew.component.html',
    styleUrls: ['./password-renew.component.scss']
})
export class PasswordRenewComponent implements OnInit, OnDestroy {
    private unsubscribeInTakeUntil = new Subject<void>();

    public isLoading = false;
    settings: AuthenticationServiceSettings;
    settingsAuth: LoginPageSettings;

    user: UserDataEntity;

    renewPasswordForm: UntypedFormGroup;
    public brand = brand;
    public brandLogoSvg = brandLogoSvg;
    public hideNewPassword = true;
    public hideConfirmPassword = true;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private formBuilder: UntypedFormBuilder,
                private authenticationService: AuthenticationService,
                private connector: OctopusConnectService) {
        this.settings = this.authenticationService.settings as AuthenticationServiceSettings;
        this.settingsAuth = settingsAuthStructure.filterModel(
            modulesSettings.authentication
        ) as LoginPageSettings;

        this.renewPasswordForm = this.formBuilder.group({
            newPassword: ['', Validators.required],
            confirmPassword: ['', Validators.required]
        }, {validators: this.mustMatch('newPassword', 'confirmPassword')});
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            const token = params.get('token');
            if (token) {
                this.verifyToken(token);
            } else {
                // Gérer le cas où le token n'est pas dans l'URL
            }
        });
    }

    private verifyToken(token: string) {
        this.isLoading = true;

        let myDate = new Date();
        let timestampDate;
        myDate.setHours(myDate.getHours() + 24);
        timestampDate = Math.floor(new Date(myDate).getTime());

        localStorage.setItem('http_accessToken', JSON.stringify(token));
        localStorage.setItem('http_expires_in', JSON.stringify(timestampDate));
        localStorage.setItem('http_currentUser', JSON.stringify({}));

        const obs: Observable<DataEntity> = this.connector.authenticated('http');

        obs.pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((user: UserDataEntity) => {
                /* set boolean field_validated_email to true */
                this.authenticationService.onAuthenticated(user);
                this.authenticationService.loggedUser.set(
                    'validated_mail',
                    true
                );
                this.authenticationService.loggedUser.save().subscribe(
                    (userUpdate: DataEntity) => {
                    },
                    (error) => {
                        console.log(error);
                    }
                );

                if(currentTimestamp() >= +user.get('expirePassword')) {
                    this.isLoading = false;
                    this.user = user;
                } else {
                    this.authenticationService.onAuthenticated(user);
                    this.router.navigate(['/login']);
                }
            }, (e) => {
                this.router.navigate(['/forgot-password', 'errorTokenNotValid']);
            });
    }

    private mustMatch(newPassword: string, confirmPassword: string) {
        return (formGroup: UntypedFormGroup) => {
            const newPasswordControl = formGroup.controls[newPassword];
            const confirmPasswordControl = formGroup.controls[confirmPassword];

            if (confirmPasswordControl.errors && !confirmPasswordControl.errors.mustMatch) {
                return;
            }

            if (newPasswordControl.value !== confirmPasswordControl.value) {
                confirmPasswordControl.setErrors({mustMatch: true});
            } else {
                confirmPasswordControl.setErrors(null);
            }
        };
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    onSubmit() {
        if (this.renewPasswordForm.valid) {
            this.authenticationService.onAuthenticated(this.user);
            this.authenticationService.loggedUser.set(
                'password',
                this.renewPasswordForm.value['newPassword']
            );
            this.authenticationService.loggedUser.save().subscribe(
                (userUpdate: DataEntity) => {
                    this.authenticationService.navigateAfterLogin();
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }
}
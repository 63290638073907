import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {concatMap, Observable} from 'rxjs';
import {map, switchMap, take, takeUntil} from 'rxjs/operators';
import {DataCollection, OctopusConnectService} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {AutoUnsubscribeTakeUntilClass} from 'shared/models';

@Injectable({
    providedIn: 'root'
})
export class IndexService extends AutoUnsubscribeTakeUntilClass {
    private cachedConcepts: { id: string, label: string }[] | null = null;

    constructor(private translateService: TranslateService,
                private octopusConnect: OctopusConnectService,
                private communicationCenter: CommunicationCenterService) {
        super();
        this.communicationCenter.getRoom('current-language').getSubject('id')
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(langId => {
                this.cachedConcepts = null;// change langue => new id cache is delete old one
                // there is a mistake on request if already done the result dont change the first time even in langue change
                // so do a trick ask twice
                this.octopusConnect.loadCollection('concepts').pipe(
                    (take(1)),
                    concatMap((firstResult: any) => {
                        // Première requête terminée, les données de celle là sont fausse c'est du cache
                        return this.octopusConnect.loadCollection('concepts').pipe(
                            take(1),
                            map((collection: DataCollection) => collection.entities),
                            map((secondResult: any) => {
                                // celle là sont bonne elles correspondent à la bonne langue
                                return {first: firstResult, second: secondResult};
                            })
                        );
                    })
                ).subscribe(({first, second}) => {
                    // on utilise les vrai résultat ceux de la seconde requete
                    this.cachedConcepts = second.map(item => {
                        let label = '';
                        if ((item.get('label') && item.get('label').toLowerCase().includes('junior')) || (item.get('name') && item.get('name').toLowerCase().includes('junior'))) {
                            label = 'junior';
                        } else if ((item.get('label') && item.get('label').toLowerCase().includes('collège')) || (item.get('name') && item.get('name').toLowerCase().includes('collège'))) {
                            label = 'explorer';
                        } else if ((item.get('label') && item.get('label').toLowerCase().includes('python')) || (item.get('name') && item.get('name').toLowerCase().includes('python'))) {
                            label = 'python';
                        }
                        return {id: item.id.toString(), label: label};
                    });
                    // on refresh les data les filtres sont à jour
                    this.communicationCenter.getRoom('root-filter').getSubject('index-refresh').next(true);
                });
            });
    }


    public pythonIdInRegardOfIndex(index: string): Observable<string> {
        if (this.cachedConcepts) {
            return this.getIdFromCachedConcepts(index);
        } else {
            return this.octopusConnect.loadCollection('concepts').pipe(
                take(1),
                map((collection: DataCollection) => collection.entities),
                map((res: any) => {
                    this.cachedConcepts = res.map(item => {
                        let label = '';
                        if ((item.get('label') && item.get('label').toLowerCase().includes('junior')) || (item.get('name') && item.get('name').toLowerCase().includes('junior'))) {
                            label = 'junior';
                        } else if ((item.get('label') && item.get('label').toLowerCase().includes('collège')) || (item.get('name') && item.get('name').toLowerCase().includes('collège'))) {
                            label = 'explorer';
                        } else if ((item.get('label') && item.get('label').toLowerCase().includes('explorer')) || (item.get('name') && item.get('name').toLowerCase().includes('explorer'))) {
                            label = 'explorer';
                        } else if ((item.get('label') && item.get('label').toLowerCase().includes('python')) || (item.get('name') && item.get('name').toLowerCase().includes('python'))) {
                            label = 'python';
                        }
                        return {id: item.id.toString(), label: label};
                    });
                    return this.getIdFromCachedConcepts(index);
                }),
                switchMap(idObservable => idObservable) // convert to simple id value
            );
        }
    }

    private getIdFromCachedConcepts(index: string): Observable<string> {
        return new Observable<string>(observer => {
            let id = '';
            switch (index) {
                case '0':
                    id = this.cachedConcepts?.find(item => item.label === 'junior')?.id || '';
                    break;
                case '1':
                    id = this.cachedConcepts?.find(item => item.label === 'explorer')?.id || '';
                    break;
                case '2':
                    id = this.cachedConcepts?.find(item => item.label === 'python')?.id || '';
                    break;
                default:
                    observer.error('Index invalide');
                    return;
            }
            observer.next(id);
            observer.complete();
        });
    }
}

import {Component, Input, OnInit} from '@angular/core';
import {DataSource} from '@angular/cdk/collections';
import {Observable} from 'rxjs';
import {TrainerService} from '@modules/groups-management/core/trainer/trainer.service';
import {UntypedFormGroup} from '@angular/forms';
import {GroupsManagementService} from '@modules/groups-management/core/services/groups-management.service';
import {DataEntity} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication';
import {FuseGroupsFormDialogData} from '@modules/groups-management/core/groups-listing/groups-form/groups-form.component';
import {AuthorizationService} from '@modules/authorization';
import {SyncRules} from '../models/rules';
import {filterType, GroupManagementConfigurationService, roleList} from '@modules/groups-management/core/services/group-management-configuration.service';

@Component({
    selector: 'app-trainer',
    templateUrl: './trainer.component.html',
    styleUrls: ['./trainer.component.scss']
})
export class TrainerComponent implements OnInit {
    static readonly componentIdentifier = 'TrainerComponent';

    public displayedFilters: string[] = []; // filter to show
    public displayedColumns = ['checkbox', 'avatar', 'trainer-name', 'email', 'type', 'access', 'buttons'];
    public dataSource: TrainerDataSource | null;

    public newTrainer: FuseGroupsFormDialogData = {
        data: {
            action: 'new',
            title: 'add_trainer',
            fields: ['avatar', 'username', 'password', 'type', 'email'],
            selects: {
                groups: () => this.trainerService.getGroups(),
                types: () => this.trainerService.getTypes()
            },
            typeEntity: 'trainer'
        },
        callback: (response) => this.newTrainerCallback(response),
        isAuthorized: (formGroup) => this.canCreate(formGroup),
    };
    public editTrainer: FuseGroupsFormDialogData = {
        isAuthorized: (trainer) => this.canEdit(trainer),
        data: {
            action: 'edit',
            title: 'edit_trainer',
            fields: ['id', 'avatar', 'username', 'password', 'type', 'email'],
            selects: {
                groups: () => this.trainerService.getGroups(),
                types: () => this.trainerService.getTypes()
            },
            typeEntity: 'trainer'
        },
        callback: (response) => this.editTrainerCallback(response)
    };

    public deleteTrainer: FuseGroupsFormDialogData = {
        data: {
            titleDialog: 'groups-management.title_remove',
            bodyDialog: 'groups-management.sure_remove_trainer',
            labelTrueDialog: 'generic.yes',
            labelFalseDialog: 'generic.no',
        },
        callback: (learner) => this.deleteTrainerCallback(learner),
        callbackWithMultiple: (list) => this.deleteListCallback(list),
        isAuthorized: (formGroup) => this.canDelete(formGroup),
    };

    constructor(
        private trainerService: TrainerService,
        private groupsManagementService: GroupsManagementService,
        private authService: AuthenticationService,
        private authorizationService: AuthorizationService,
        private groupManagementConfigurationService: GroupManagementConfigurationService
    ) {
        try {
            this.displayedFilters = this.groupManagementConfigurationService.displayFilterByOriginAndRole(filterType.trainers, roleList[this.authService.accessLevel]);
        } catch (ex) {
            console.error('erreur getting settings trainer component 75 ' + ex);
        }
    }

    ngOnInit(): void {
        this.dataSource = new TrainerDataSource(this.trainerService);
        this.groupsManagementService.setHeaderTitle('groups-management.my_trainers');

        this.setTrainerColumnsdByAccessLevel();
        this.setTrainersFieldsByAccessLevel();
    }

    public newTrainerCallback(response: UntypedFormGroup): Observable<DataEntity> {
        if (response) {
            return this.trainerService.addTrainer(response.getRawValue());
        }
    }

    public editTrainerCallback(response: UntypedFormGroup): Observable<DataEntity> {
        if (response) {
            return this.trainerService.saveTrainer(response.getRawValue());
        }
    }

    public deleteTrainerCallback(trainer: any): Observable<boolean> {
        return this.trainerService.deleteTrainer(trainer);
    }

    public deleteListCallback(trainers: any): void {
        for (const trainer of trainers) {
            this.trainerService.deleteTrainer(trainer);
        }
    }

    private setTrainersFieldsByAccessLevel(): void {
        const fields = this.groupsManagementService.settings.trainer.fields[this.authService.accessLevel] ?
            this.groupsManagementService.settings.trainer.fields[this.authService.accessLevel] : this.groupsManagementService.settings.trainer.fields['default'];

        this.newTrainer.data.fields = [...fields];
        this.editTrainer.data.fields = [...fields];
        // push id because need for edit mode but field doesn't exist in form
        if (this.editTrainer.data.fields) {
            this.editTrainer.data.fields.push('id');
        }
    }

    private setTrainerColumnsdByAccessLevel(): void {
        this.displayedColumns = this.groupsManagementService.settings.trainer.columns[this.authService.accessLevel] ?
            this.groupsManagementService.settings.trainer.columns[this.authService.accessLevel] : this.groupsManagementService.settings.trainer.columns['default'];
    }

    private canCreate(trainer): boolean {
        return this.authorizationService.currentUserCan(SyncRules.CreateTrainer, undefined, trainer);
    }

    private canDelete(trainer): boolean {
        return this.authorizationService.currentUserCan(SyncRules.DeleteTrainer, undefined, trainer);
    }

    private canEdit(trainer): boolean {
        return this.authorizationService.currentUserCan(SyncRules.EditTrainer, undefined, trainer);
    }
}

export class TrainerDataSource extends DataSource<any> {
    constructor(private trainerService: TrainerService) {
        super();
    }

    connect(): Observable<any[]> {
        return this.trainerService.onTrainersChanged;
    }

    disconnect(): void {
    }
}

import {RouterModule, Routes} from '@angular/router';
import {ActivitiesPipesModule} from '@modules/activities/core/pipes/activitiesPipes.module';
import {AppaireComponent} from '@modules/activities/core/player-components/appaire/appaire.component';
import {AudioComponent} from '@modules/activities/core/player-components/audio/audio.component';
import {AwarenessComponent} from './awareness/awareness.component';
import {CommonModule} from '@angular/common';
import {CommonSharedModule} from '@modules/activities/core/shared-components/common-shared.module';
import {DividerComponent} from './divider/divider.component';
import {DragulaModule} from 'ng2-dragula';
import {DrawLineComponent} from '@modules/activities/core/player-components/draw-line/draw-line.component';
import {ExternalComponent} from '@modules/activities/core/player-components/external/external.component';
import {FillInBlanksChildComponent} from '@modules/activities/core/player-components/fill-in-blanks/fill-in-blanks-child/fill-in-blanks-child.component';
import {FillInBlanksComponent} from '@modules/activities/core/player-components/fill-in-blanks/fill-in-blanks.component';
import {FuseSharedModule} from '@fuse/shared.module';
import {ImageComponent} from './image/image.component';
import {ImageZoningComponent} from './image-zoning/image-zoning.component';
import {InteractiveImageComponent} from './interactive-image/interactive-image.component';
import {KeyboardLatexFieldComponent} from './short-answer/keyboard-latex-field/keyboard-latex-field.component';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MediaComponent} from './media/media.component';
import {MemoryComponent} from './memory/memory.component';
import {MemoryFindZoneComponent} from './memory/memory-find-zone/memory-find-zone.component';
import {MultiComponent} from './multi/multi.component';
import {MultimediaComponent} from '@modules/activities/core/player-components/multimedia/multimedia.component';
import {MultipleChoiceComponent} from './multiple-choice-grid/multiple-choice/multiple-choice.component';
import {MultipleChoiceGridComponent} from './multiple-choice-grid/multiple-choice-grid.component';
import {NgModule} from '@angular/core';
import {OrdOnComponent} from '@modules/activities/core/player-components/ordon/ordon.component';
import {OrderMatchingComponent} from './order-matching/order-matching.component';
import {PollComponent} from './poll/poll.component';
import {PositionAndColorDotDirective} from './interactive-image/directives/position-and-color-dot.directive';
import {QcmComponent} from '@modules/activities/core/player-components/qcm/qcm.component';
import {QcuComponent} from '@modules/activities/core/player-components/qcu/qcu.component';
import {SharedMaterialModule} from 'shared/shared-material.module';
import {SharedTranslateModule} from 'shared/shared-translate.module';
import {ShortAnswerComponent} from '@modules/activities/core/player-components/short-answer/short-answer.component';
import {SummaryActivityComponent} from '@modules/activities/core/player-components/summary-activity/summary-activity.component';
import {TextMatchingComponent} from './text-matching/text-matching.component';
import {ToolComponent} from './tool/tool.component';
import {TrueFalseComponent} from '@modules/activities/core/player-components/true-false/true-false.component';
import {TtsModule} from '../../../../tts/tts.module';
import {VideoComponent} from './video/video.component';
import {VoiceRecorderActivityComponent} from './voice-recorder-activity/voice-recorder-activity.component';
import {WhatIsMissingComponent} from './what-is-missing/what-is-missing.component';
import {ImageZoningPlaySoundComponent} from '@modules/activities/core/player-components/image-zoning-play-sound/image-zoning-play-sound.component';
import {ImageZoningAbstractComponent} from '@modules/activities/core/player-components/image-zoning-base/image-zoning-abstract.component';
import {ZoneGeneratorComponent} from './image-zoning/zone-generator/zone-generator/zone-generator.component';
import {FeedbackComponent} from './feedback/feedback.component';
import {WhatIsMissingAudioComponent} from '@modules/activities/core/player-components/what-is-missing-audio/what-is-missing-audio.component';
import {ZoomableComponent} from '@modules/activities/core/player-components/zoomable/zoomable.component';
import {QcuBaseComponent} from '@modules/activities/core/player-components/qcu-base/qcu-base.component';
import {VoiceRecorderReadOnlyComponent} from '@modules/activities/core/shared-components/voice-recorder/voice-recorder-read-only/voice-recorder-read-only.component';
import { EmbeddedActivitiesComponent } from './embeded-activities/embedded-activities.component';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {IsUserLogged} from '../../../../guards';

const ActivitiesRoutes: Routes = [
    {
        path: 'qcm/:activityId',
        component: QcmComponent,
    },
    {
        path: 'qcu/:activityId',
        component: QcuComponent,
    },
    {
        path: 'pointimg/:activityId',
        component: ImageZoningComponent
    },
    {
        path: 'imgsoundzone/:activityId',
        component: ImageZoningPlaySoundComponent,
    },
    {
        path: 'truefalse/:activityId',
        component: TrueFalseComponent,
    },
    {
        path: 'ordon/:activityId',
        component: OrdOnComponent,
    },
    {
        path: 'orderingmatching/:activityId',
        component: OrderMatchingComponent,
    },
    {
        path: 'text-matching/:activityId',
        component: TextMatchingComponent,
    },
    {
        path: 'awareness/:activityId',
        component: AwarenessComponent,
    },
    {
        path: 'orderingmatching/:activityId',
        component: OrderMatchingComponent
    },
    {
        path: 'text-matching/:activityId',
        component: TextMatchingComponent
    },
    {
        path: 'fillinblanks/:activityId',
        component: FillInBlanksComponent,
    },
    {
        path: 'shortanswer/:activityId',
        component: ShortAnswerComponent,
    },
    {
        path: 'draw-line/:activityId',
        component: DrawLineComponent,
    },
    {
        path: 'draw-line/:activityId',
        component: DrawLineComponent
    },
    {
        path: 'appaire/:activityId',
        component: AppaireComponent,
    },
    {
        path: 'media/:activityId',
        component: MediaComponent,
    },
    {
        path: 'divider/:activityId',
        component: DividerComponent,
    },
    {
        path: 'audio/:activityId',
        component: AudioComponent,
    },
    {
        path: 'video/:activityId',
        component: VideoComponent,
    },
    {
        path: 'image/:activityId',
        component: ImageComponent,
    },
    {
        path: 'interactiveimage/:activityId',
        component: InteractiveImageComponent,
    },
    {
        path: 'summary/:activityId',
        component: SummaryActivityComponent,
    },
    {
        path: 'medias/:activityId',
        component: MultimediaComponent,
    },
    {
        path: 'external/:activityId',
        component: ExternalComponent,
    },
    {
        path: 'gcm/:activityId',
        component: MultipleChoiceGridComponent,
    },
    {
        path: 'tool/:activityId',
        component: ToolComponent,
    },
    {
        path: 'memory/:activityId',
        component: MemoryComponent,
    },
    {
        path: 'missing/:activityId',
        component: WhatIsMissingComponent,
    },

    {
        path: 'recording/:activityId',
        component: VoiceRecorderActivityComponent,
    },
    {
        path: 'imgsoundzone/:activityId',
        component: ImageZoningPlaySoundComponent,
    },
    {
        path: 'missingaudio/:activityId',
        component: WhatIsMissingAudioComponent,
    },
];

const routes: Routes = [
    {
        path: 'embedded',
        component: EmbeddedActivitiesComponent,
        canActivate: [IsUserLogged],
        children: [...ActivitiesRoutes, {
            path: 'multi/:subLessonId',
            component: MultiComponent,
            children: ActivitiesRoutes,
        }],
    },
    {
        path: 'multi/:subLessonId',
        component: MultiComponent,
        children: ActivitiesRoutes,
    },
    ...ActivitiesRoutes,
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        SharedTranslateModule,
        CommonSharedModule,
        MatButtonModule,
        MatGridListModule,
        MatIconModule,
        MatCardModule,
        ActivitiesPipesModule,
        DragulaModule.forRoot(),
        MatProgressSpinnerModule,
        SharedMaterialModule,
        TtsModule,
    ],
    declarations: [
        AppaireComponent,
        AudioComponent,
        DividerComponent,
        FillInBlanksComponent,
        FillInBlanksChildComponent,
        ImageComponent,
        InteractiveImageComponent,
        MediaComponent,
        MultiComponent,
        VoiceRecorderActivityComponent,
        VoiceRecorderReadOnlyComponent,
        OrdOnComponent,
        TextMatchingComponent,
        AwarenessComponent,
        PositionAndColorDotDirective,
        QcmComponent,
        QcuComponent,
        QcuBaseComponent,
        ShortAnswerComponent,
        DrawLineComponent,
        TrueFalseComponent,
        VideoComponent,
        PollComponent,
        SummaryActivityComponent,
        MultimediaComponent,
        ExternalComponent,
        MultimediaComponent,
        MultipleChoiceGridComponent,
        MultipleChoiceComponent,
        ToolComponent,
        OrderMatchingComponent,
        ImageZoningComponent,
        ImageZoningPlaySoundComponent,
        ImageZoningAbstractComponent,
        ZoneGeneratorComponent,
        KeyboardLatexFieldComponent,
        MemoryComponent,
        MemoryFindZoneComponent,
        WhatIsMissingComponent,
        FeedbackComponent,
        WhatIsMissingAudioComponent,
        ZoomableComponent,
        EmbeddedActivitiesComponent,
    ],
    exports: [
        AppaireComponent,
        AudioComponent,
        DividerComponent,
        FillInBlanksComponent,
        FillInBlanksChildComponent,
        ImageComponent,
        MediaComponent,
        OrdOnComponent,
        TextMatchingComponent,
        AwarenessComponent,
        QcmComponent,
        QcuComponent,
        QcuBaseComponent,
        RouterModule,
        ShortAnswerComponent,
        DrawLineComponent,
        TrueFalseComponent,
        VideoComponent,
        SummaryActivityComponent,
        MultimediaComponent,
        MultiComponent,
        ExternalComponent,
        InteractiveImageComponent,
        ToolComponent,
        ZoneGeneratorComponent,
        MemoryComponent,
        WhatIsMissingComponent,
        ImageZoningPlaySoundComponent,
        VoiceRecorderActivityComponent,
        ImageZoningPlaySoundComponent,
        WhatIsMissingAudioComponent,
        OrderMatchingComponent,
        VoiceRecorderReadOnlyComponent,
        ImageZoningComponent,
    ],
    providers: [{ provide: MAT_DIALOG_DATA, useValue: {} }]
})
export class PlayersModule {
}

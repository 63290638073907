import {Directive, ElementRef, inject, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {ActivitiesConfigurationService} from '@modules/activities/core/services/activities-configuration.service';
import {TypologyLabel} from '@modules/activities/core/typologies/typology.label';
import {ActivitiesService} from '../activities.service';
import {LessonsService} from '../lessons/services/lessons.service';
import {AutoUnsubscribeTakeUntilClass} from 'shared/models/auto-unsubscribe-take-until.class';
import {ButtonComponentConfigInterface} from '@modules/activities/core/models/button-component.interface';
import {DataEntity} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {combineLatest, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {ActivityGranule, AnswerAppInterface, AnswerResultInterface} from '@modules/activities/core/models';
import {LessonNavigationService} from '../lesson-navigation.service';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';
import {answerStatusEnum} from '@modules/activities/core/models/answer-status.enum';
import {userSaveEndPointEnum} from '../models/user-save-end-point.enum';
import {AnswerInterface} from '@modules/activities/core/models/answer.interface';
import * as _ from 'lodash-es';
import {ContextualService} from "@modules/activities/core/services/contextual.service";

export const TIME_DISPLAYING_CORRECTION = 1500; // temps pour afficher le feedback visuel de la correction.
export const TIME_DELAY_BEFORE_SAVE = 1000; // temps pour afficher le feedback visuel de la correction.
export interface FeedbackInterface<W> {
    btnTitle?: string;
    title?: string;
    content?: string;
    contentWithTermInterface?: string;
    innerHtmlContent?: string;
    callback?: () => W;
    state?: ItemAnswerStateEnum;
}

export interface ActivityConfigInterface {
    language?: {
        wording?: string;
        instruction?: string;
    };
    muted?: boolean;
}

@Directive()
/**
 * Il s'agit d'une couche d'abstraction autour de toutes les activités.
 * Elle est sensé gérer le chargement/déchargement de l'activité et autres choses communes a toute activité
 *
 * On rajoute ce décorateur "Directive" parce que le moteur IVY a besoin de comprendre que c'est un component.
 * Et la base d'un component est une directive (pas de template & selecteur)
 *
 * Votre IDE/linter risque de souligner le nom de la classe en rouge, parce que le nom de la classe ne fini pas par "directive".
 * C'est volontaire.
 * Toutefois, on ne peut pas ajouter une règle pour ignorer le linter exceptionnellement car la règle doit etre placé précisément au dessus de la déclaration de la classe
 * Donc on perdrais l'association de cette documentation et la classe.
 */
export abstract class BaseActivityComponent<T extends ActivityGranule, U extends AnswerInterface|AnswerAppInterface = AnswerInterface> extends AutoUnsubscribeTakeUntilClass implements OnInit {
    // Parfois un object, parfois un id, attention c'est à refacto
    @Input() public activityId?: T; //todo à supprimer et cleaner la ou c'est implémenté
    @Input() public activity?: T;
    @Input() displayForSummary?: boolean;
    @Input() preview?: boolean;
    @Input() questionTypeName?: string;
    // utilisé en cas de succession du même type d'exo pour initialiser l'activité (l'instance n'est pas détruite)
    @Input() refresh?: ReplaySubject<DataEntity>;
    /**
     * id de l'assignation en cours
     * @deprecated semble ne pas toujours être valorisé donc soit elle est buguée, soit elle n'est plus utilisée (voir lessonService.currentAssignment
     */
    @Input('contextId') public assignmentId?: string;

    @Input() readable?: boolean;
    @ViewChild('readMe', {read: ElementRef}) readMe: ElementRef<HTMLElement>;
    // on differencie la sauvegarde par default d'une nouvelle sauvegarde
    public answerSaved: boolean;
    // on answer click => show correction, no need to wait until lesson's due date's finished
    public autoCorrection: boolean;
    // les boutons recuperés depuis les settings
    public buttons: ButtonComponentConfigInterface[] = [];
    // afficher la solution
    public displaySolution: boolean;
    // instruction de l'exercice
    public instruction: string;
    // consigne enregistré de l'exercice (alternative au TTS même comportement)
    public instructionAudio: string;
    // status pour savoir si la sauvegarde est terminé
    public isSaving: boolean;
    public isTTSSpeaking: { id: string, value: boolean }; // to know if TTS is played
    public wordingAlreadyReadWithTts = false; // to know if we launch TTS on wording when instruction TTS finished
    // permet de definir la mise en page de l'exercice, récuperer depuis le champs config de la reference de l'activité
    public isTwoColumns = false;
    // permet de definir la mise en page de l'exercice, récuperer depuis le champs config de la reference de l'activité
    public isVertical = false;
    // permet de savoir que l'utilisateur a testé sa reponse
    public testAnswer: boolean;
    // consigne de l'exercice (normalement seulement utilisé dans les qcm/qcu/quiz)
    public wording: string;
    // consigne enregistré de l'exercice (alternative au TTS même comportement)
    public wordingAudio: string;
    public answerResult: Subject<AnswerResultInterface> = new Subject<AnswerResultInterface>();
    // type de l'activité
    protected activityType: TypologyLabel;
    // status de(s) réponse(s) cf answerStatusEnum
    public answerStatus = answerStatusEnum.missing;
    // réponses selectionnées par l'utilisateur ou par default.
    public answersSelected: (U & {state?: ItemAnswerStateEnum})[] = [];
    // réponses disponible à sélectionner.
    public availableAnswers: (U & {state?: ItemAnswerStateEnum})[] = [];
    // le pourcentage de réussite de l'activité (utilisé sur iboost)
    protected activityPercentile = 0;
    // l'index de l'activité dans un parcours ou sous-parcours (sert à la sauvegarde local sans assignation)
    protected activityStepIndex: number;
    // Utilisé pour afficher des messages lorsque l'on teste une réponse pour aider l'utilisateur (iboost)
    public displayFeedback = false;
    // la sauvegarde de l'utilisateur pour cette activité. une sauvegarde paar defaut est crée si aucune sauvegarde existante
    protected userSave: DataEntity;
    // endpoint à ciblé pour créer ou éditer une sauvegarde
    protected userSaveEndPoint: userSaveEndPointEnum;
    // at the end of an exercise we can have a feedback if exist before passing to next exercise
    public feedbackEndExo: string = null;
    // On utilisera feedBackEndExo pour afficher le feedback de fin d'exo par la suite
    public feedbackFromApi: FeedbackInterface<any> = null;
    public feedbackButtons: ButtonComponentConfigInterface[] = [];
    // activity attributes
    protected activityAttributes: T['attributes'];
    // langue de l'activité définie par la config de l'activité (on priorise la langue de l'instruction à celle du wording)
    protected language: string;
    private buttonsExceptionsFromActivity: ButtonComponentConfigInterface[] = [];
    private nextActionAlreadyTriggered: boolean;
    protected referenceActivityGranule: T['attributes']['reference'];

    public isActivityEmbedded = false;
    public isActivityEmbeddedDone = false;


    protected activatedRoute= inject(ActivatedRoute)
    protected activitiesService= inject(ActivitiesService)
    protected activitiesConfigurationService = inject(ActivitiesConfigurationService)
    protected communicationCenter= inject(CommunicationCenterService)
    protected contextualService= inject(ContextualService)

    /** @deprecated TODO les activité ne devrait pas avoir conscience qu'elle sont dans une lesson */
    protected lessonsConfigurationService= inject(LessonsConfigurationService)
    /** @deprecated TODO les activité ne devrait pas avoir conscience qu'elle sont dans une lesson */
    protected lessonNavigationService= inject(LessonNavigationService)
    /** @deprecated TODO les activité ne devrait pas avoir conscience qu'elle sont dans une lesson */
    protected lessonsService= inject(LessonsService)

    constructor() {
        super();
        this.communicationCenter.getRoom('activities')
            .getSubject('isActivityEmbedded')
            .subscribe((isActivityEmbedded: boolean) => {
                this.isActivityEmbedded = isActivityEmbedded;
        });
    }

    /**
     * manage all event to send to lesson component by communication center
     * for change progress bar state :
     * reset
     * number of question
     * state of asnwer made : answer is true or false
     * @private
     */
    public manageProgressBarEventToSend(answer: AnswerResultInterface): void {
        if (answer) {
            this.activitiesService.answersProgressBarMultiZone.push(answer);
            if (this.lessonsConfigurationService.getActivitiesBroadcastLifeCycle()) {
                const completionData = {
                    id: `activity/${this.activity.id}`,
                    result: {
                        success: answer.state === ItemAnswerStateEnum.currentlyCorrect || answer.state === ItemAnswerStateEnum.wasCorrect
                    },
                    assignmentId: this.lessonsService.currentAssignment?.id || null
                };
                this.communicationCenter.getRoom('lrs')
                    .getSubject('activity_attempt')
                    .next(completionData);
            }
            this.communicationCenter.getRoom('progress-bar-exo').getSubject('answerResult').next(true);
        }
    }

    ngOnInit(): void {
        this.autoCorrection = this.activitiesService.settings.autoCorrection || false;
        this.displayFeedback = this.activitiesService.settings.displayFeedback || false;
        this.activatedRoute.params.subscribe(() => {
            this.initialize();
        });
        if (this.refresh) {
            this.refresh.subscribe((activity: T) => {
                if (activity && activity.get('metadatas').typology && activity.get('metadatas').typology.label === this.activityType) {
                    this.activity = activity;
                    this.initialize();
                }
            });
        }

        this.contextualService.dataLessonFirstDocumentName$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((callback) => callback(this.lessonsService.currentLesson?.get('metadatas').files[0]?.filename));
        this.contextualService.dataLessonFirstDocumentURL$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((callback) => callback(this.lessonsService.currentLesson?.get('metadatas').files[0]?.uri));
    }

    protected activityInitialized(): void {
        if (this.lessonsConfigurationService.getActivitiesBroadcastLifeCycle()) {
            const initData = {
                id: `activity/${this.activity.id}`,
                assignmentId: this.lessonsService.currentAssignment?.id || null
            };

            this.communicationCenter.getRoom('lrs')
                .getSubject('activity_initialize')
                .next(initData);
        }

        if (this.lessonsService.currentLesson) {
            this.lessonsService.checkAndOpenSurvey();
            this.lessonsService.updateCurrentLessonState(this.activity);
        }
    }

    /**
     * initialize activity.
     * 'activity' is used when we start the activity from the activities list in a sub lesson
     * for refresh the component exp: start activity then second activity is the same type 'qcu => qcu'
     * @protected
     */
    protected initialize(): void {
        this.isActivityEmbeddedDone = false;
        this.reset(true);
        if (this.displayForSummary && !!this.activity) {
            // ici sur ecran sommaire
            this.initializePlayerWithActivity(this.activity.attributes as T['attributes']);
        } else {
            if (this.activatedRoute?.snapshot?.queryParams?.navigateDirectlyToSummary) {
                // ici redirection vers sommaire forcé
                this.navigateToEndOfLesson();
            } else {
                // ici chargement normale d'une activité
                const activityEntity = this.lessonsService.activities
                    .find((act) => +act.id === +this.activatedRoute?.snapshot?.params?.activityId);
                if (activityEntity) {
                    this.activity = activityEntity as T;
                    this.initializePlayerWithActivity(activityEntity.attributes as T['attributes']);
                } else {
                    this.activitiesService.loadActivitiesFromId(this.activatedRoute?.snapshot?.params.activityId)
                        .pipe(take(1))
                        .subscribe(act => {
                            this.activity = act as T;
                            this.initializePlayerWithActivity(act.attributes as T['attributes']);
                        });
                }
            }
        }
    }

    /**
     * initialise le player avec les data de l'activité pour démarrer l'activité
     * @param attributes
     * @private
     */
    private initializePlayerWithActivity(attributes: T['attributes']): void {
        this.activityAttributes = attributes;
        this.activityStepIndex = this.lessonsService.activityIndex;
        this.feedbackEndExo = attributes?.reference?.finalFeedback;
        this.language = this.getConfig()?.language?.instruction || this.getConfig()?.language?.wording;
        this.setActivityTypeAndUserSaveEndpoint(attributes);
        this.setContentData(attributes);
        const activitiesWithoutCorrection: TypologyLabel[] = [
            TypologyLabel.divider,
            TypologyLabel.audio,
            TypologyLabel.external,
            TypologyLabel.image,
            TypologyLabel.interactiveImage,
            TypologyLabel.tool,
            TypologyLabel.summary,
            TypologyLabel.video,
            TypologyLabel.recording,
            TypologyLabel.multimedia,
            TypologyLabel.awareness
        ];
        if (activitiesWithoutCorrection.includes(this.activityAttributes.metadatas.typology?.label)) {
            const answerResult: AnswerResultInterface = {
                id: +this.activity.id,
                state: ItemAnswerStateEnum.missing,
                isLast: true,
                forceStateToCurrentlyCorrect: true
            };
            this.manageProgressBarEventToSend(answerResult);
        }
        this.activityInitialized();
        this.initializeButtons();
        this.nextActionAlreadyTriggered = false;
    }

    /**
     * met a jour l'endpoint pour la sauvegarde et le type de l'activité
     * @param activityAttributes les attributs de l'activité
     * @protected
     */
    protected setActivityTypeAndUserSaveEndpoint(activityAttributes): void {
        this.activityType = activityAttributes && activityAttributes.metadatas && activityAttributes.metadatas.typology.label || null;
        this.userSaveEndPoint = userSaveEndPointEnum[this.activityType];
    }

    /**
     * effectue l'action du bouton cliqué (sauvegarder/tester/suivant/précedant/modifier/etc..
     * @param action exemple d'action : save / test / reset etc..
     * @param preActionMatrix tableau 'd'action' à faire avant de lancer la principale 'action' (prop 1)
     * @param actionFromButton
     * @param feedback
     * @protected
     */
    protected doAction<V>(action: string, preActionMatrix: string[] = null, actionFromButton = false, feedback?: FeedbackInterface<V>): void {
        if (this[action]) {
            if (action === 'validate') {
                this.validate();
            } else {
                if (preActionMatrix) {
                    const obs = preActionMatrix.map((preAction: string) => preAction === 'save' ? this.save(null, actionFromButton) : this[preAction]());
                    combineLatest(obs).pipe(
                        mergeMap(() => {
                            if (action === 'save') {
                                return this.save(null, actionFromButton);
                            }
                            if (!this.isActivityEmbedded && (this.feedbackEndExo || feedback?.content || feedback?.innerHtmlContent)) {
                                if (this.feedbackEndExo && !feedback?.content && !feedback?.innerHtmlContent) {
                                    feedback = {content: this.feedbackEndExo};
                                }
                                this.feedbackEndExoOpenModal({...feedback, callback: () => this[action]()});
                                return of(null);
                            }
                            return this[action]();
                        })
                    ).subscribe();
                } else {
                    if (action === 'save') {
                        this.save(null, actionFromButton).pipe(take(1)).subscribe();
                    } else if (!this.isActivityEmbedded && (this.feedbackEndExo || feedback?.content || feedback?.innerHtmlContent)) {
                        if (this.feedbackEndExo && !feedback?.content && !feedback?.innerHtmlContent) {
                            feedback = {content: this.feedbackEndExo};
                        }
                        this.feedbackEndExoOpenModal({...feedback, callback: () => this[action]()});
                    } else {
                        this[action]().pipe(take(1)).subscribe();
                    }
                }
            }
        } else {
            throw new Error(`no method ${action} implemented`);
        }
    }

    /**
     * test la réponse en y apportant une correction
     * @protected
     */
    protected abstract checkAnswer(): void;

    /**
     * revoir sa réponse (contient une logique differente selon l'activité)
     * @protected
     */
    protected abstract reviewAnswer(): void;

    /**
     * voir la solution de l'activité (contient une logique differente selon l'activité)
     * @protected
     */
    protected abstract seeAnswerSolution(): void;

    /**
     * une fois la sauvegarde récuperée, elle est traitée pour que les réponses sauvegardés soit inserer dans l'activité
     * @protected
     */
    protected abstract setAnswer(): void;

    /**
     * sauvegarde les réponse avant de sauvegarder la 'user-save'
     * @protected
     */
    protected abstract saveAnswer(): Observable<any>;

    /**
     * une fois les infos de l'activité crée, initialise l'activité avec les infos
     * @protected
     */
    protected abstract setContentData(attributes: T['attributes']): void;

    /**
     * recupere la note de l'utilisateur, l'ancienne provenant de l'user-save et la nouvelle en fonction des réponses.
     * @protected
     */
    protected abstract getGrade(): { oldGrade: number, newGrade: number };

    /**
     * define the number of attemps for the activity
     * @protected
     */
    protected abstract getAttempts(): number;

    /**
     *  initialise les boutons de l'activité, la configuration des boutons est récuperé des settings.
     * @protected
     */
    protected initializeButtons(): void {
        // TODO: use lessonConfigurationService to get the buttons
        if (this.activitiesService.settings.feedbackButtons) {
            this.feedbackButtons = this.activitiesService.settings.feedbackButtons.slice()
                .map((button: ButtonComponentConfigInterface) => {
                    return this.setDefaultOptionForButton(button, true);
                });
        }
        if (!this.displayForSummary && this.activitiesService.settings.buttons && this.settingsForButton) {
            this.buttons = this.settingsForButton.slice()
                .map((button: ButtonComponentConfigInterface) => {
                    return this.setDefaultOptionForButton(button);
                });
        }
    }

    /**
     * initialise les boutons d'action et les mets à jour selon l'action ou l'etat de l'activité
     * @param button
     * @protected
     */
    protected setDefaultOptionForButton(button: ButtonComponentConfigInterface, isButtonForFeedBack?: boolean): ButtonComponentConfigInterface {
        let customButton: ButtonComponentConfigInterface = {type: null};
        customButton.svgIcon = button.svgIcon || null;
        customButton.classCss = button.classCss || ['button-' + button.type];
        customButton.classCssIcon = button.classCssIcon || ['button-icon-' + button.type];
        customButton.disable = button.disable === true || button.options && !!button.options[this.activityType]?.disable; // for information: if button.display === undefined, need to return false !
        customButton.display = button.display !== false; // for information: if button.display === undefined, need to return true !
        customButton.title = button.title || 'button.' + button.type;
        customButton.preActionMatrix = button.preActionMatrix || null;
        customButton.type = button.type || null;
        customButton.options = button.options || null;

        if (!isButtonForFeedBack) {
            this.optionForButton(customButton);
        }

        this.buttonsExceptionsFromActivity.forEach((button: ButtonComponentConfigInterface) => {
            if (button.type === customButton.type) {
                customButton = _.merge({}, customButton, button);
            }
        });

        this.addOptionsToButtons(customButton);

        return customButton;
    }

    public addOptionsToButtons(customButton: ButtonComponentConfigInterface) {
        if (customButton.options && customButton.options[this.activityType]) {
            for (const field in customButton.options[this.activityType]) {
                switch (customButton.options[this.activityType][field].case) {
                    case 'poll':
                        if (this.lessonsService.currentActivityInSubLesson) {
                            const index = this.lessonsService.subLessonContentEdited
                                .findIndex((act: DataEntity) => +act.id === +this.lessonsService.currentActivityInSubLesson.id);
                            const activity = this.lessonsService.subLessonContentEdited[+index + 1];
                            if (activity && activity.get('metadatas').typology.label === 'summary') {
                                customButton[field] = customButton.options[this.activityType][field].value;
                            }
                        }
                        break;
                    case 'disableWithDelay':
                        customButton[field] = true;
                        setTimeout(() => {
                            // TODO gérer le cas "c'est le bouton 'next', la propriété 'display' mais c'est la derniere activité"
                            customButton[field] = false;
                        }, this.lessonsService.isAtLeastTrainer() ? 0 : customButton.options[this.activityType][field].value);

                        break;
                    case 'displayWithDelay':
                        customButton[field] = false;
                        setTimeout(() => {
                            // TODO gérer le cas "c'est le bouton 'next', la propriété 'display' mais c'est la derniere activité"
                            customButton[field] = true;
                        }, this.lessonsService.isAtLeastTrainer() ? 0 : customButton.options[this.activityType][field].value);

                        break;
                    case 'force':
                    default:
                        customButton[field] = customButton.options[this.activityType][field].value;
                }
            }
        }
    }

    /**
     * définis differentes options des boutons permettant de defenir quand rendre le bouton activé/desactivé, afficher ou non
     * @param button
     * @protected
     */
    protected optionForButton(button: ButtonComponentConfigInterface): void {
        switch (button.type) {
            case 'solution':
                button.disable = this.displaySolution;
                break;

            case 'save':
                button.display = !(this.lessonsService.isLessonCorrected() || this.lessonsService.isLessonValidated())
                    && (!this.lessonsService.isAtLeastTrainer() || this.lessonsService.isAtLeastTrainer() && this.lessonsService.isLessonTraining());
                button.disable = this.isSaving || (!!this.answerStatus
                    && this.answerStatus !== 4
                    && this.userSave
                    && this.userSave.get('changed') !== this.userSave.get('created'));
                break;

            case 'modify':
                button.display = !(this.lessonsService.isLessonCorrected() || this.lessonsService.isLessonValidated()) && !this.lessonsService.isAtLeastTrainer();
                button.disable = this.isSaving;
                break;

            case 'test':
                button.disable = this.answerStatus === answerStatusEnum.missing || this.displaySolution || this.testAnswer;
                break;

            case 'review':
                button.disable = !this.displaySolution && !this.testAnswer;
                break;

            case 'reset':
                button.disable = false; // TODO: check if reset need to be disabled. no case existing.
                break;

            case 'previous':
                button.disable = this.activitiesService.presentArrayElementIndex === 0;
                break;

            case 'next':
                // no need to disable here for now
                if (this.lessonsService.isCurrentActivityLast && !this.isActivityEmbedded) {
                    button.title = 'activities.lesson.finish';
                }
                break;
            case 'validate':
                button.disable = this.isSaving;
                break;
        }
    }

    /**
     * au clique sur un bouton d'action, on effectue la tache et on met à jour les boutons
     * @param button
     */
    public onAction(button: ButtonComponentConfigInterface): void {
        this.doAction(button.type, button.preActionMatrix, true);
        this.lessonsService.lessonButtonClicked.next(true);
        this.initializeButtons();
    }

    /**
     * lorsque l'on effectue un changement dans l'activité (répondre, enlever, etc..) cela nous permet de savoir a quel moment afficher certains boutons
     * @param e
     */
    public onOptionChange(e: boolean): void {
        if (e) {
            this.answerStatus = answerStatusEnum.answered;
        } else {
            this.answerStatus = answerStatusEnum.missing;
        }
        this.initializeButtons();
    }

    /**
     * récupere les settings des boutons à afficher dans l'activité
     * @protected
     */
    protected get settingsForButton(): ButtonComponentConfigInterface[] {
        let assignmentType = 'training';
        if (this.lessonsService.isLessonTest()) {
            assignmentType = 'preview';
        }
        if (this.lessonsService.currentAssignment && this.lessonsService.currentAssignment.get('type_term')) {
            assignmentType = this.lessonsService.currentAssignment.get('type_term').label;
        }
        // TODO: use lessonConfigurationService to get the buttons
        let buttons = this.activitiesService.settings.buttons?.player?.[assignmentType] || [];
        return buttons;
    }

    /**
     * recupere la sauvegarde ou en crée une par defaut, définis les réponses présentes dans la sauvegarde
     * @private
     */
    protected loadUserSave(): void {
        if (this.isActivityEmbedded) {
            return;
        }
        // sert dans le cas ou il n'y a pas d'assignation, retrouve l'usersave dans les saves locales.
        const step = this.lessonsService.subLessonContentEdited.length ?
            this.lessonsService.subLessonContentEdited.findIndex((activity) => +activity.id === +this.activity.id)
            : this.lessonsService.currentLesson.get('reference').findIndex((activity) => +activity.id === +this.activity.id);
        this.activitiesService.getUserSave(this.activity.id, this.assignmentId, null, step, this.activatedRoute?.snapshot?.queryParams?.assignatedUserId).pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(userSave => {
                if (userSave) {
                    this.userSave = userSave;
                    if (this.activitiesService.settings.setAnswerWithUserSave) {
                        this.setAnswer();
                    }
                    this.activityPercentile = this.getGrade() && Math.round(this.getGrade().oldGrade * 100) || 0;
                } else if (this.lessonsService.isMyAssignment()) {
                    this.save().subscribe();
                }
                this.isSaving = false;
                // initialise les boutons de l'activité, la configuration des boutons est récuperé des settings.
                this.initializeButtons();
            });
    }

    /**
     * calcule le pourcentage depuis la note récuperer ci dessus.
     * @private
     */
    protected get calculateUserSavePercent(): number {
        return Math.round(this.getGrade().newGrade * 100);
    }

    /**
     * define the number of attemps for the activity
     * @private
     */
    protected get attempts(): number {
        return Math.round(this.getAttempts());
    }

    /**
     * dans le cas ou l'user navigue dans une sous lesson.
     * @param direction
     * @protected
     */
    protected navigateInSubLesson(direction: string): void {
        switch (direction) {
            case 'previous':
                this.communicationCenter
                    .getRoom('activities')
                    .next('previous', true);
                break;
            case 'next':
                this.communicationCenter
                    .getRoom('activities')
                    .next('next', true);
                break;
            default:
                throw new Error('no direction provided');
        }
    }
    /**
     * verifie si l'on est dans un sous parcours.
     * @protected
     */
    protected isActivityInSubLesson(): boolean {
        return !!this.activity && !!this.lessonsService.subLessonContentEdited.find((activity) => +activity.id === +this.activity.id);
    }

    /**
     * modifie la réponse
     * @protected
     */
    protected modify(): Observable<boolean> {
        this.reset(false, 'modify');
        return of(true);
    }

    /**
     * reviens à l'activité précédente
     * @protected
     */
    protected previous(): Observable<boolean> {
        if (this.isActivityInSubLesson()) {
            this.navigateInSubLesson('previous');
        } else {
            this.activitiesService.loadPreviousActivity();
        }
        return of(true);
    }

    /**
     * passe à l'activité suivante
     * @protected
     */
    protected next(): Observable<boolean> {
        if (!this.nextActionAlreadyTriggered) {
            if (this.lessonsService.isAssignmentWithMetacognition()) {
                this.activitiesService.metacognition();
            } else {
                if (this.isActivityInSubLesson()) {
                    this.navigateInSubLesson('next');
                } else {
                    if (this.activitiesService.activitiesArray.length <= this.activitiesService.presentArrayElementIndex + 1) {
                        if (this.lessonsService.shouldDisplayRecapAtEndOfLesson()) {
                            this.lessonNavigationService.navigateToRecap(
                                this.activitiesService.currentLesson.id,
                                !!this.activitiesService.currentAssignment ? '/followed/assignment' : '/',);
                        }
                        if (this.lessonsConfigurationService.getUseSummaryPageInsteadOfRecap() === true) {
                            this.navigateToEndOfLesson();
                        }
                    } else {
                        this.activitiesService.loadNextActivity(this.activatedRoute?.snapshot?.queryParams || {});
                    }
                }
            }

            if (this.lessonsConfigurationService.getActivitiesBroadcastLifeCycle()) {
                // For ubolino we can only pass to next question when the answer is correct. Send the activity completed statement here
                const completionData = {
                    id: `activity/${this.activity.id}`,
                    result: {
                        success: true,
                        completion: true
                    },
                    assignmentId: this.lessonsService.currentAssignment?.id || null
                };
                this.communicationCenter.getRoom('lrs')
                    .getSubject('activity_complete')
                    .next(completionData);
            }
        }
        this.nextActionAlreadyTriggered = true;
        return of(true);
    }

    private navigateToEndOfLesson(): void {
        const queryParams: Params = this.activatedRoute?.snapshot?.queryParams || {};
        const urlPrefix = this.lessonsService.currentAssignment ? '/followed/assignment' : null;
        this.communicationCenter.getRoom('progress-bar-exo').getSubject('hide').next(true);
        this.activitiesService.preparePlayerToTheEndOfTheLesson();
        if (this.lessonsConfigurationService.getUseSummaryPageInsteadOfRecap() === true) {
            // hide progress bar
            if (this.lessonsConfigurationService.showRewardPageAfterSubLessonEnded() && !queryParams.navigateDirectlyToSummary) {
                this.lessonNavigationService.navigateToReward(this.lessonsService.currentLesson.id, urlPrefix, queryParams);
            } else {
                this.lessonNavigationService.navigateToSummary(this.lessonsService.currentLesson.id, urlPrefix, queryParams);
            }
        } else {
            this.lessonNavigationService.navigateToRecap(this.activitiesService.currentLesson.id, urlPrefix);
        }
    }

    /**
     * display modal of rewards (after activity is completed)
     * @protected
     */
    protected showActivityRewards(): Observable<boolean> {
        const callback: (mode: string) => void = (mode) => {
            if (mode === 'exit') {
                this.communicationCenter.getRoom('activities')
                    .next('backToLesson', {forceUrl: null, initialiseSubject: true});
            } else {
                this.doAction('next');
            }
        };

        const isLastActivity = this.activitiesService.activitiesArray.length <= this.activitiesService.presentArrayElementIndex + 1;

        this.communicationCenter
            .getRoom('gamification')
            .next('showRewards', {feedback: 'hello', title: 'title', callback, userSave: this.userSave, image: this.activityAttributes.file?.uri, showNextButton: !isLastActivity});

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('refresh').next({'assignated_user': [this.userSave.get('uid')]});

        if ((this.userSave.get('config')?.savesCount === 15 || this.userSave.get('config')?.savesCount === 40) && !this.activitiesService.isGar) {
            this.activitiesService.openReviewPopup();
        }

        this.lessonsService.checkAndOpenSurvey();
        return of(true);
    }


    /**
     * sauvegarde des réponses de l'activité, on envoie l'id de l'assignation (si existante) le status de l'activité, la sauvegarde (si existante)
     * et l'index de l'activité dans le parcours ou le sous parcours.
     * @private
     * @param status FormPlayerComponent use prop 'status', need to check if it is really needed...
     */
    protected save(status?: answerStatusEnum, actionFromButton?: boolean): Observable<DataEntity> {
        const preview = this.activatedRoute.snapshot.queryParams.preview;
        if (preview && preview === 'true') {
            this.preview = true;
        }
        if (this.displayForSummary || this.preview) {
            return of(null);
        }
        if (actionFromButton) {
            this.checkAnswer();
        }
        if (!!status) {
            this.answerStatus = status;
        }
        this.isSaving = true;
        if (this.userSave) {
            this.userSave.set('grade', +this.calculateUserSavePercent);
            try {
                this.userSave.set('errorsCount', this.attempts);
            } catch (e) {
                console.warn(e);
            }
        }
        this.lessonsService.setProgress();
        const grade = this.getGrade();
        this.lessonsService.setAssignGrade(grade['newGrade'], grade['oldGrade']);
        return this.saveAnswer().pipe(
            take(1),
            mergeMap((answers) => {
                return this.activitiesService
                    .saveUserSave(this.activity.id.toString(),
                        this.assignmentId,
                        answers || this.answersSelected.map((answer) => answer.id),
                        this.answerStatus,
                        this.userSaveEndPoint,
                        this.userSave,
                        this.activityStepIndex)
                    .pipe(
                        take(1),
                        tap((userSave: DataEntity) => this.userSave = userSave),
                        tap(() => this.isSaving = false),
                        tap(() => this.initializeButtons())
                    );
            })
        );
    }

    protected test(): Observable<DataEntity> {
        this.displaySolution = false;
        this.testAnswer = true;
        this.checkAnswer();
        return this.save();
    }

    /**
     * revoir sa réponse apres avoir affiché la solution ou l'avoir testé
     * @protected
     */
    protected review(): Observable<boolean> {
        this.reviewAnswer();
        this.displayFeedback = false;
        this.displaySolution = false;
        this.testAnswer = false;
        return of(true);
    }

    /**
     * permet d'afficher la solution de l'exercice
     */
    protected solution(): Observable<boolean> {
        this.seeAnswerSolution();
        this.displayFeedback = false;
        this.displaySolution = true;
        this.testAnswer = false;
        return of(true);
    }

    /**
     * reinitialise l'activité
     * @param resetAllSubscribe
     * @param type
     * @protected
     */
    protected reset(resetAllSubscribe = false, type: string = null): Observable<boolean> {
        this.isActivityEmbeddedDone = false;
        this.answersSelected = []; // reset after end of exo
        this.answerSaved = false;
        this.answerStatus = answerStatusEnum.missing;
        this.displayFeedback = false;
        this.isSaving = false;
        this.displaySolution = false;
        this.testAnswer = false;
        if (resetAllSubscribe) {
            this.buttonsExceptionsFromActivity = [];
            this.userSave = null;
            if (this.unsubscribeInTakeUntil) {
                this.unsubscribeInTakeUntil.next();
                this.unsubscribeInTakeUntil.complete();
            }
            this.unsubscribeInTakeUntil = new Subject<void>();
        }
        this.activitiesService.displayActions.next(true);
        this.activitiesService.doesUserResponsed.next(false);
        return of(true);
    }

    public get showPercentile(): string {
        return (this.activityPercentile ? ' ' + this.activityPercentile + '%' : ' 0%') || null;
    }

    /**
     * at end of an exo a finalFeedback could exist if it s open a modal
     * @param currentAnswer
     * @public
     */
    public feedbackEndExoOpenModal<V>(feedbackContent: FeedbackInterface<V>): void {
        if (!this.isActivityEmbedded && !this.displayForSummary) {
            this.activitiesService.openFeedbackModal(feedbackContent);
        }
    }

    /**
     * affiche ou non le pourcentage de bonne reponse dans l'activité
     */
    public get isPercentileDisplayAllowed(): boolean {
        const asTrainer = this.lessonsService.isAtLeastTrainer() && (this.lessonsService.isLessonEvaluation() || this.lessonsService.isLessonTraining());
        const asLearner = this.lessonsService.isAtLeastTrainer() === false && (this.lessonsService.isLessonEvaluation() && this.testAnswer);
        return (asTrainer || asLearner) && this.activitiesService.settings.isPercentileDisplayAllowed && !this.isActivityEmbedded;
    }

    public clickToTts(): void {
        this.readMe.nativeElement.click();
    }

    public isAutoReadActive(key: 'instruction' | 'wording'): boolean {
        const autoReadSettings = this.activitiesService.getAutoReadSettings();
        let activityAutoReadSettings: ('instruction' | 'wording')[];

        if (autoReadSettings.hasOwnProperty(this.activityType)) {
            activityAutoReadSettings = autoReadSettings[this.activityType];
        } else if (autoReadSettings.hasOwnProperty('default')) {
            activityAutoReadSettings = autoReadSettings['default'];
        } else {
            return false;
        }

        return activityAutoReadSettings.includes(key);
    }

    public isInstructionImg(): boolean {
        return this.instruction?.includes('<img');
    }

    public isWordingImg(): boolean {
        return this.wording?.includes('<img');
    }

    /**
     * validate answer only, validate !== save
     * @param answer
     * @protected
     */
    protected abstract validate(answer?: U & {state?: ItemAnswerStateEnum}): void;

    /**
     * return le champ config de l'activité
     * @protected
     */
    protected getConfig(): ActivityConfigInterface {
        return this.activityAttributes?.reference?.config;
    }

    /**
     * permet de stocker et gerer les exceptions des boutons spécifiques aux activités
     * @param buttons
     */
    public addExceptionsForButtonsFromActivity(buttons: ButtonComponentConfigInterface[]) {
        this.buttonsExceptionsFromActivity = [];
        this.buttonsExceptionsFromActivity = buttons;
    }

    /**
     * check if data is undefined or empty (data === '')
     * @param data
     */
    public isDataEmpty(data: string) {
        return !data || data === '';
    }

    public isConsigneContainMedia(data = ''): boolean {
        const imgRegexp = /<img/gi;
        const audioRegexp = /<audio/gi;
        const videoRegexp = /<video/gi;
        return data !== '' && data?.search(imgRegexp) !== -1 || data?.search(audioRegexp) !== -1 || data?.search(videoRegexp) !== -1;
    }

    /**
     * replay activity
     */
    public replayActivity(): void {
        this.reset(false, 'replay');
        this.initialize();
    }

    public actionFromFeedback(button: ButtonComponentConfigInterface): void {
        this.doAction(button.type, button.preActionMatrix, true, this.feedbackFromApi);
    }

    public setFeedBackFromApi(feedback?: FeedbackInterface<any>, answerStatus?: answerStatusEnum): void {
        if (this.isActivityEmbedded) {
            if ([TypologyLabel.awareness, TypologyLabel.multimedia, TypologyLabel.external, TypologyLabel.recording].includes(this.activityType)) {
                this.feedbackFromApi = {contentWithTermInterface: 'activities.embedded_consulted', state: ItemAnswerStateEnum.missing};
            } else {
                if (answerStatus === answerStatusEnum['correct']) {
                    this.feedbackFromApi = {contentWithTermInterface: 'activities.embedded_succeeded', state: ItemAnswerStateEnum.wasCorrect};
                } else {
                    this.feedbackFromApi = {contentWithTermInterface: 'activities.embedded_failed', state: ItemAnswerStateEnum.incorrect};
                }
            }
        } else {
            // TODO if feedback is not empty, we need to set feedbackFromApi with it
        }
    }
}
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { DataEntity, OctopusConnectService } from 'octopus-connect';
import { AccountManagementProviderService } from '@modules/account-management/core/account-management-provider.service';
import { AuthenticationService } from '@modules/authentication';

@Injectable()
export class IsUserNotLogged {

    userInformation: DataEntity;

    constructor(
        private connector: OctopusConnectService,
        private usersProvider: AccountManagementProviderService,
        private authService: AuthenticationService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const subject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

        this.connector.authenticated('http').pipe(take(1)).subscribe(
            (userData: DataEntity) => {
                // User is authenticated, navigate to home
                this.router.navigate(['']);
                subject.next(false);
            },
            () => {
                // User is not authenticated
                subject.next(true);
            }
        );

        return subject.asObservable();
    }
}

import {Component, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {FormProfile} from '@modules/account-management/core/form-profil.class';
import {ProfileService} from '@modules/account-management/core/profile/profile.service';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication';
import {FieldsOptions} from 'shared/fieldsOptions';
import {AccountManagementProviderService} from '@modules/account-management';
import {OPTIONAL_EMAIL_DOMAIN} from '@modules/account-management/core/account-management-provider.service';
import {TranslateService} from '@ngx-translate/core';
import {brand} from '../../../../../../settings';

@Component({
    selector: 'fuse-edit-profile-forms',
    templateUrl: './forms.component.html',
    styleUrls: ['./forms.component.scss']
})
export class FuseFormsComponent implements OnInit {
    form: UntypedFormGroup;
    formErrors: any;

    public canEditAvatar: boolean;
    public canEditYouAre: boolean;
    public allowedExtensions: string;
    public displayEndpointError: string;
    public allowedMaxSize: string;
    public isPngTooBig = false;
    public formIsReady = false;
    public displayGenericError = false;
    public displayLabelFieldInProfile = false;

    userInformation: DataEntity;
    defaultUserInformation: FormProfile;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private profileProvider: ProfileService,
        public authenticationService: AuthenticationService,
        private octopusConnect: OctopusConnectService,
        public accountProvider: AccountManagementProviderService,
        private translate: TranslateService,
    ) {
        this.defaultUserInformation = new FormProfile({}, this.octopusConnect);
        // Reactive form errors
        this.formErrors = {
            label: {},
            lastName: {},
            firstName: {},
            email: {},
            you_are: {},
            find_us: {},
            region: {},
            level: {},
            password: {},
            passwordConfirm: {},
        };
    }

    ngOnInit(): void {
        this.defaultUserInformation.completeLoading.subscribe(() => {
            this.initialize();
        });
        this.displayLabelFieldInProfile = this.settingsAccount.displayLabelFieldInProfile;
    }

    private initialize(): void {
        this.allowedExtensions = this.profileProvider.getAllowedExtensions().join(', ');
        this.allowedMaxSize = this.profileProvider.getAllowedMaxSize().toString();
        this.canEditAvatar = this.profileProvider.getCanEditAvatar();
        this.canEditYouAre = this.profileProvider.getCanEditYouAre();
        this.profileProvider.userInformationOnChanged.subscribe(userInformation => {
            this.userInformation = userInformation;
        });
        // to manage case where back send an array instead of an object
        if (Array.isArray(this.userInformation.get('level'))) {
            this.userInformation.set('level', this.userInformation.get('level')[0]);
            console.error('please : back data user profil level is not well formated. Level attribute is in an array here but he must be an object.');
        }

        // Reactive Form
        this.form = this.formBuilder.group({
            label: [this.userInformation.get('label'), this.requiredFields('label')],
            lastName: [this.userInformation.get('lastName'), this.requiredFields('lastName')],
            firstName: [this.userInformation.get('firstName'), this.requiredFields('firstName')],
            email: [{value: this.filterOptionalEmail(this.userInformation.get('email')), disabled: this.userInformation.get('sso')}, [Validators.email]],
            you_are: [{value: this.userInformation.get('you_are'), disabled: !this.canEditYouAre}, this.requiredFields('you_are')],
            find_us: [this.userInformation.get('find_us'), this.requiredFields('find_us')],
            region: [this.userInformation.get('region') ? +this.userInformation.get('region').id : '', this.requiredFields('region')],
            level: [this.userInformation.get('level') ? +this.userInformation.get('level').id : null, this.requiredFields('level')],
            newsletter: [this.userInformation.get('newsletter')],
            password: [''],
            passwordConfirm: ['', confirmPassword],
            institution: this.userInformation.get('institution')
        });

        this.form.valueChanges.subscribe(() => {
            this.onFormValuesChanged();
        });
        if (brand.toString() === 'amazon_python') {
            this.emailRequiredConditionnal();
        }

        this.formIsReady = true;
    }

    private emailRequiredConditionnal() {
        this.form.get('newsletter').valueChanges.subscribe(checked => {
            const emailControl = this.form.get('email');
            if (checked) {
                emailControl.setValidators([Validators.required, Validators.email]);
                emailControl.markAsTouched();
            } else {
                emailControl.clearValidators();
                emailControl.markAsUntouched();
            }
            emailControl.updateValueAndValidity();
        });
    }

    /***
     * this.isPngTooBig is use to change color of text
     * @param isSizeTooBig : true if file is too big to be upload
     */
    public changeColorIfTooBig(isSizeTooBig: boolean): void {
        this.isPngTooBig = isSizeTooBig;
    }

    onFormValuesChanged(): void {
        for (const field in this.formErrors) {
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.formErrors[field] = {};

            // Get the control
            const control = this.form.get(field);

            if (control && control.dirty && !control.valid) {
                this.formErrors[field] = control.errors;
            }

            if (this.form.get('password').value !== '' && this.form.get('passwordConfirm').value === '') {
                this.form.get('passwordConfirm').setErrors({passwordsNotMatch: true});
                this.formErrors['passwordConfirm'] = this.form.get('passwordConfirm').errors;
            } else {
                if (this.form.get('password').value === '' && this.form.get('passwordConfirm').value === '') {
                    this.form.get('passwordConfirm').setErrors(null);
                }
            }

        }
    }

    public getUserLanguage(): string {
        return this.translate.currentLang;
    }

    editProfile(): void {
        this.displayGenericError = false;

        if (!this.form.invalid) {
            const user = {
                label: this.form.value.label,
                lastName: this.form.value.lastName,
                firstName: this.form.value.firstName,
                email: this.filterEmailToSet(this.form.value.email),
                you_are: this.form.value.you_are,
                find_us: this.form.value.find_us,
                region: this.form.value.region, // id is store in .region directly
                level: this.form.value.level,
                newsletter: this.form.value.newsletter,
                role_field: this.form.value.role_field,
                password: this.form.value.password,
                institution: this.form.value.institution,
                language: this.getUserLanguage()
            };

            this.profileProvider.editUser(user).subscribe((isSuccess: boolean | object) => {
                if (isSuccess === false) {
                    this.setMode(true);
                    this.displayGenericError = true;
                } else if (typeof isSuccess === 'object' && isSuccess['code'] === 400) {
                    this.setMode(true);
                    this.displayEndpointError = isSuccess['data'].response.title;
                }
            });
        }

    }

    setMode(mode: boolean): void {
        this.form = this.formBuilder.group({
            label: [this.userInformation.get('label')],
            lastName: [this.userInformation.get('lastName')],
            firstName: [this.userInformation.get('firstName')],
            email: [this.filterOptionalEmail(this.userInformation.get('email')), [Validators.email]],
            you_are: [this.userInformation.get('you_are')],
            find_us: [this.userInformation.get('find_us')],
            region: [this.userInformation.get('region')],
            level: [this.userInformation.get('level')],
            newsletter: [this.userInformation.get('newsletter')],
            password: [''],
            passwordConfirm: ['', confirmPassword],
            institution: ['']

        });

        this.profileProvider.editMode = mode;
    }

    displayField(name: string): boolean {
        return this.profileProvider.getEditableFields().some(f => f.label === name);
    }

    requiredFields(name: string): ValidationErrors | undefined {
        const field = this.profileProvider.getEditableFields().find(f => f.label === name);
        if (!!field && !this.userInformation.get('sso')) {
            return FieldsOptions.isRequired(field) ? Validators.required : undefined;
        }
        return undefined;
    }

    public get settings(): { [key: string]: any } {
        return this.profileProvider.settings;
    }

    public get settingsAccount(): { [key: string]: any } {
        return this.accountProvider.settings;
    }

    private filterOptionalEmail(email: string): string {
        if (email.indexOf(OPTIONAL_EMAIL_DOMAIN) < 0) {
            return email;
        } else {
            return '';
        }
    }

    private filterEmailToSet(email: string): string {
        if (email === '') {
            return this.userInformation.get('email');
        } else {
            return email;
        }
    }
}


function confirmPassword(control: AbstractControl): { passwordsNotMatch: true } {
    if (!control.parent || !control) {
        return;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if (!password || !passwordConfirm) {
        return;
    }

    if (password.value !== '' && passwordConfirm.value === '') {
        return {
            passwordsNotMatch: true
        };
    }

    if (passwordConfirm.value === '') {

        return;

    }

    if (password.value !== passwordConfirm.value) {
        return {
            passwordsNotMatch: true
        };
    }
}

import {FillBlanksContent, UpdatableFillBlanksAnswersContent} from './fill-blanks.content';
import {FlashCardsContent, UpdatableFlashCardsContent} from './flash-cards.content';
import {AnswersContent, UpdatableAnswersContent} from './answers.content';
import {MediaContent, UpdatableMediaContent} from './media.content';
import {MultipleChoiceContent} from './multiple-choice.content';
import {InteractiveImageActivityContent} from './interactive-image-activity.content';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect';

export type ActivityContent = AnswersContent | FillBlanksContent | FlashCardsContent[] | MediaContent[] | MultipleChoiceContent | InteractiveImageActivityContent | [null];
export type UpdatableActivityContent = UpdatableAnswersContent | UpdatableFillBlanksAnswersContent | UpdatableFlashCardsContent | UpdatableMediaContent;
export type ActivityContentEntity<T = ActivityContent> = TypedDataEntityInterface<T>
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {WysiwygComponent} from 'shared/components/wysiwyg/wysiwyg.component';
import {SharedMaterialModule} from './shared-material.module';
import {SharedTranslateModule} from './shared-translate.module';
import {FuseConfirmDialogModule} from 'fuse-core/components/confirm-dialog/confirm-dialog.module';
import {DynamicModule} from '@fuse/components/dynamic/dynamic.module';
import {BasicPageModule} from 'fuse-core/components/basic-page/basic-page.module';
import {CardModule} from '@fuse/components/card/card.module';
import {GettingStartedModule} from 'fuse-core/components/getting-started/getting-started.module';
import {SelectCheckAllComponent} from './components/select-check-all/select-check-all.component';
import {CollectionModule} from 'fuse-core/components/collection/collection.module';
import {ActivtyCardComponent} from './components/activty-card/activty-card.component';
import {NgClass, NgIf} from '@angular/common';
import {ProgressionModule} from 'fuse-core/components/progression/progression.module';
import {GenericFileUploadInterfaceComponent} from 'shared/media/generic-file-upload-interface/generic-file-upload-interface.component';

@NgModule({
    imports: [
        CardModule,
        DynamicModule,
        FuseConfirmDialogModule,
        ProgressionModule,
        BasicPageModule,
        CollectionModule,
        GettingStartedModule,
        SharedMaterialModule,
        SharedTranslateModule,
        NgClass,
        CKEditorModule,
        NgIf,
        FormsModule
    ],
    declarations: [
        SelectCheckAllComponent,
        ActivtyCardComponent,
        GenericFileUploadInterfaceComponent,
        WysiwygComponent,
    ],
    exports: [
        CardModule,
        DynamicModule,
        FuseConfirmDialogModule,
        BasicPageModule,
        CollectionModule,
        GettingStartedModule,
        SharedMaterialModule,
        SharedTranslateModule,
        SelectCheckAllComponent,
        ActivtyCardComponent,
        GenericFileUploadInterfaceComponent,
        WysiwygComponent,
    ]
})
export class SharedModule {}

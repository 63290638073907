import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CardsService} from 'fuse-core/components/card/cards.service';
import {BreacrumbFields} from 'fuse-core/components/card/lesson-card-breadcrumb/breadcrumb.interface';

@Component({
    selector: 'app-lesson-card-breadcrumb',
    templateUrl: './lesson-card-breadcrumb.component.html',
    styleUrls: ['./lesson-card-breadcrumb.component.scss']
})
export class LessonCardBreadcrumbComponent implements OnInit, OnChanges {

    @Input() public fields: BreacrumbFields;
    @Input() previousTargetName: string = '';
    public target = '';
 
    constructor() {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.target = this.previousTargetName;
    }
}

import {Component, Inject, ElementRef, ViewChild, AfterViewInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {DomSanitizer} from '@angular/platform-browser';
import {EventService} from 'shared/event.service';

declare var window: any;
@Component({
    selector: 'fuse-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class FuseConfirmDialogComponent implements AfterViewInit {
    public titleDialog: string;
    public bodyDialog: any;
    public labelConfirmDialog: string;
    public labelConfirmDialogPlaceHolder: string;
    public labelTrueDialog: string;
    public labelFalseDialog: string;
    public labelOtherDialog: string; // other button for return info to parent that we have click on it
    public confirmMessage: string;
    public showReportbutton: boolean;
    public resourceId: string;
    public inputValue: string;
    private isAutoFocus?: boolean = false; // is pass to true if in data autofocus field exist
    private inputErrorLengthMessage = '';
    public originalInputValue = '';
    private maxInputSize = 100000;
    @ViewChild('inputToFocus') inputToFocus: ElementRef;

    /**
     * Constructor
     *
     * @param {MatDialogRef<FuseConfirmDialogComponent>} dialogRef
     */
    constructor(
        private domSanitizer: DomSanitizer,
        public dialogRef: MatDialogRef<FuseConfirmDialogComponent>,
        private eventService: EventService,
        @Inject(MAT_DIALOG_DATA) data) {

        this.titleDialog = data.titleDialog;
        this.bodyDialog = this.domSanitizer.bypassSecurityTrustHtml(data.bodyDialog);
        this.labelTrueDialog = data.labelTrueDialog;
        this.labelFalseDialog = data.labelFalseDialog;
        this.labelConfirmDialog = data.labelConfirmDialog;
        this.labelOtherDialog = data.labelOtherDialog;
        this.labelConfirmDialogPlaceHolder = data.labelConfirmDialogPlaceHolder;
        this.showReportbutton = data.showReportbutton;
        this.resourceId = data.resourceId;
        this.inputValue = data.inputValue;
        this.originalInputValue = data.inputValue;
        this.maxInputSize = data.maxInputSize;
        this.inputErrorLengthMessage = data.inputErrorLengthMessage;
        if (data.autofocus) {
            this.isAutoFocus = true;
        }
    }

    /**
     * autofocus on input field if data contain field autofocus by default value is false
     */
    ngAfterViewInit(): void {
        const video = document.getElementById("videoPlayer") as HTMLVideoElement;
        if (video) {
            let hasPlayed = false;

            const sources = video.getElementsByTagName("source");

            if (sources.length > 0) {
                const firstSource = sources[0]; // Récupérer la première balise <source>
                const src = firstSource.src;    // Accéder à l'attribut src de la balise <source>

                video.addEventListener("play", (event) => {
                    if (!hasPlayed) {
                        this.eventService.trackEvent('Video', 'play', src);
                        hasPlayed = true;
                    }
                });

                video.addEventListener("ended", (event) => {
                    this.eventService.trackEvent('Video', 'ended', src);
                    hasPlayed = false;
                });
            }
        }

        if (this.isAutoFocus) {
            setTimeout(() => this.inputToFocus.nativeElement.focus(), 1000);
        }
    }

    // input have a limit of size
    public isLengthInputTooLong(): boolean {
        return this.inputValue ? this.inputValue.length > this.maxInputSize : false;
    }
}

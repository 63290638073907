import {Directive, ElementRef, AfterViewInit, ChangeDetectorRef, Input, OnChanges, SimpleChanges} from '@angular/core';

@Directive({
    selector: '[appDynamicTabIndex]'
})
/**
 * cette directive permet de créer les tab-index de tout les élèment html listé 'A', 'BUTTON', 'INPUT', 'SELECT', 'TEXTAREA', etc...
 * elle parcours récursivement tout les élèment html et les ordonnes en fonction de leurs position réel après rendu css javascript etc..
 * l'utilisation est la suivante :
 * si il y a des données dynamique passer le tableau d'élèment dans reload (dans l'exemple data)
 * afin qu'il recrée l'index au changement des données
 * (l'index doit être présent sur toute la page pour pouvoir aller sur les bouton des lignes d'une table par exemple)
 * <div appDynamicTabIndex [reload]="data">
 * si aucune donnée dynamique:
 * <div appDynamicTabIndex>
 * note : we can exclude somes element to add tabindex adding exclude-elem-to-order-directive class to it
 */
export class DynamicTabIndexDirective implements AfterViewInit, OnChanges {

    @Input() reload: any[] = [];

    constructor(private el: ElementRef, private cdr: ChangeDetectorRef) {
    }

    ngAfterViewInit(): void {
        this.updateTabIndexRecursive(this.el.nativeElement, 1);
        this.cdr.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        // quand on a des donnée dynamique on a besoin de refaire l'index quand celle-ci sont la exemple un mat-table
        if (changes.reload && changes.reload.currentValue.length > 0) {
            setTimeout(() => {
                this.updateTabIndexRecursive(this.el.nativeElement, 1);
                this.cdr.detectChanges();
            });
        }
    }

    private updateTabIndexRecursive(parentElement: HTMLElement, startIndex: number): number {
        let currentIndex = startIndex;
        // enfants du conteneur parent
        const childElements = Array.from(parentElement.children);

        // Tri des éléments par leur position réel après compilation du CSS et javascript
        const sortedElements = childElements.sort((a: HTMLElement, b: HTMLElement) => {
            const aStyle = window.getComputedStyle(a);
            const bStyle = window.getComputedStyle(b);
            const aIndex = parseInt(aStyle.getPropertyValue('order') || '0');
            const bIndex = parseInt(bStyle.getPropertyValue('order') || '0');
            return aIndex - bIndex;
        });

        sortedElements.forEach((element: HTMLElement) => {
            if (element === parentElement || this.isInteractiveElement(element)) {
                element.tabIndex = currentIndex++;
            }
            // recursif pour les enfant des enfant pour aller au plus profond des élèments html
            currentIndex = this.updateTabIndexRecursive(element, currentIndex);
        });
        return currentIndex;
    }

    private isInteractiveElement(element: HTMLElement): boolean {
        // liste des élèments sur lesquels on mettra un tabindex à faire évoluer selon les besoins
        const interactiveTags = [
            'A', 'BUTTON', 'INPUT', 'SELECT', 'TEXTAREA', 'MAT-BUTTON-TOGGLE',
            'MAT-BUTTON', 'MAT-CHECKBOX', 'MAT-RADIO-BUTTON', 'MAT-SELECT', 'MAT-INPUT', 'MAT-TOOLBAR',
            'MAT-TABLE', 'MAT-ROW', 'TR'
        ];

        // on a besoin que le container principal (de la zone des filtres dans ce cas) soit ciblé pour qu'il rentre dedans
        // pour ça j'utilise ici la classe. ces soucis sont due au html généré complexe de matérial.
        // de même pour la zone d'action des card
        if (element.classList.contains('content ') || element.classList.contains('forceAddingTabIndex')) {
            return true;
        }
        return interactiveTags.includes(element.tagName.toUpperCase()) && !element.classList.contains('exclude-elem-to-order-directive');
    }
}

import {ChangeDetectorRef, Component, inject, Input} from '@angular/core';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {of} from 'rxjs';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect';
import {BaseActivityComponent} from '../base-activity.component';
import {Observable} from 'rxjs';
import {v4 as uuidv4} from 'uuid';
import {ActivityGranule, MediaContent, MediaGranule} from '@modules/activities/core/models';

export type MultimediaActivityGranule = ActivityGranule<MediaContent[] , { type: string }>
@Component({
    selector: 'app-multimedia',
    templateUrl: './multimedia.component.html'
})
export class MultimediaComponent extends BaseActivityComponent<MultimediaActivityGranule> {
    @Input() public activityInput?: DataEntity;
    public isInitializing: boolean;
    public resources: TypedDataEntityInterface<MediaGranule>[] = [];
    public title: string;
    public typeOfContent: string;
    public uuid: string;

    private ref = inject(ChangeDetectorRef)
    private octopusConnect = inject(OctopusConnectService)

    protected setContentData(activityAttributes: MultimediaActivityGranule['attributes']): void {
        this.uuid = uuidv4();
        // todo: need to check in case of activity "GHOST" (cf new editor) if we get the metadatas
        if (activityAttributes.metadatas) {
            this.activityType = activityAttributes.metadatas.typology.label;
        }
        this.title = activityAttributes.metadatas.title;
        this.instruction = activityAttributes.reference.instruction;
        this.wording = activityAttributes.reference.wording;
        this.instructionAudio = activityAttributes.reference.instructionAudio;
        this.wordingAudio = activityAttributes.reference.wordingAudio;

        this.typeOfContent = activityAttributes.reference.config?.type;
        if (activityAttributes.reference.activity_content) {
            this.resources = this.getGranule(activityAttributes.reference.activity_content[0]);
        } else {
            this.resources = [];
        }
        setTimeout(() => {
            super.setFeedBackFromApi();
            this.isActivityEmbeddedDone = true;
        }, 5000);
        this.isInitializing = false;
    }

    /**
     * Extract the granule's DataEntity from the given activityContent data
     * @param activityContent - field from activity
     * @returns granule from the content as a DataEntity
     */
    private getGranule(activityContent: MediaContent) {
        if (activityContent && activityContent.granule && activityContent.granule.length) {
            const resources = activityContent.granule.filter((media) => !!media).map((granule) => {
                if (granule && 'attributes' in granule) {
                    return  granule as unknown as TypedDataEntityInterface<MediaGranule>;
                }
                return new DataEntity('granule', <any> granule, this.octopusConnect, granule.id) as TypedDataEntityInterface<MediaGranule>;
            });

            return resources;
        }

        return [];
    }

    /**
     * Resets the current component
     */
    protected reset(): Observable<boolean> {
        this.isInitializing = true;
        delete this.instruction;
        delete this.wording;
        this.ref.detectChanges();
        return super.reset();
    }

    public videoEmbedUrl(video) {
        const videoConfig = {
            src: video.get('reference').url,
        };
        if (this.activitiesService.settings.urlVideoException.some((urlException) => video.get('reference').url.includes(urlException))) {
            videoConfig['type'] = 'video/mp4';
        }
        return {
            config: videoConfig,
        };
    }

    /**
     * create answer entered by the user.
     * no need to create answer because answer already exist.
     * method needed for save in baseActivityComponent
     * @protected
     */
    protected saveAnswer(): Observable<number[]> {
        return of(null);
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    protected checkAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected setAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected getGrade(): { oldGrade: number, newGrade: number } {
        throw new Error('Method not implemented.');
    }


    /**
     * change state of TTS
     * TODO better to move in base-activity, need to add ref (ChangeDetectorRef) in contructor
     * @param data
     */
    public speakStateChanged(data: { id: string, value: boolean }): void {
        this.isTTSSpeaking = data;
        this.ref.detectChanges();
        if (!this.wordingAlreadyReadWithTts && this.isTTSSpeaking && !this.isTTSSpeaking.value && this.uuid !== this.isTTSSpeaking.id) {
            this.clickToTts();
        }
        if (this.isTTSSpeaking && !this.isTTSSpeaking.value && this.uuid !== this.isTTSSpeaking.id) {
            this.wordingAlreadyReadWithTts = true;
        }
    }

    public get isTTSReadingText(): boolean {
        return this.isTTSSpeaking && this.isTTSSpeaking.id === this.uuid && this.isTTSSpeaking.value;
    }

    protected getAttempts(): number {
        throw new Error('Method not implemented.');
    }

    protected initialize(): void {
        this.resources = [];
        super.initialize();
    }


    protected validate(): void {
        throw new Error('Method not implemented.');
    }

    public isMuted(): boolean{
        return this.getConfig()?.muted;
    }
}

import {AnswerInterface} from '@modules/activities/core/models';
import {MergeTypes} from 'shared/merge';
import {PartialExcept} from 'shared/utils/partial.except';

export interface FillBlanksContent {
    id: string | number;
    answer_text: string;
    answers: AnswerInterface[];
}


export type UpdatableFillBlanksAnswersContent = PartialExcept<MergeTypes<[
    Omit<FillBlanksContent, 'id' | 'answers'>,
    {answers: number[]}
]>, 'answers'>;
import { NgModule } from '@angular/core';

import { FuseIfOnDomDirective } from '@fuse/directives/fuse-if-on-dom/fuse-if-on-dom.directive';
import { FuseInnerScrollDirective } from '@fuse/directives/fuse-inner-scroll/fuse-inner-scroll.directive';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseMatSidenavHelperDirective, FuseMatSidenavTogglerDirective } from '@fuse/directives/fuse-mat-sidenav/fuse-mat-sidenav.directive';
import {DynamicComponentDirective} from 'fuse-core/directives/dynamic-component/dynamic-component.directive';
import {MoveElementDirective} from 'fuse-core/directives/move-element/move-element.directive';
import { DiableAutofillDirective } from '@fuse/directives/disable-autofill/disable-autofill.directive';
import {SpinnerDirective} from 'fuse-core/directives/spinner/spinner.directive';
import {DayRelativeNameToStringDirective} from 'fuse-core/directives/day-relative-name-to-string/day-relative-name-to-string.directive';
import {TimeSlotToStringDirective} from 'fuse-core/directives/time-slot-to-string/time-slot-to-string.directive';
import { AnchorScrollHighlightDirective } from './anchor-scroll-highlight/anchor-scroll-highlight.directive';
import {TabOrderDirective} from 'fuse-core/directives/tab-order/tab-order.directive';
import {DynamicTabIndexDirective} from 'fuse-core/directives/app-dynamic-tab-index.directive';
import {MoveFocusToNextAllowedElementDirective} from 'fuse-core/directives/move-focus-to-next-allowed-element/move-focus-to-next-allowed-element.directive';
import {TimeSlotToStringPipe} from "fuse-core/directives/time-slot-to-string/time-slot-to-string.pipe";

@NgModule({
    declarations: [
        FuseIfOnDomDirective,
        FuseInnerScrollDirective,
        FuseMatSidenavHelperDirective,
        FuseMatSidenavTogglerDirective,
        FusePerfectScrollbarDirective,
        DynamicComponentDirective,
        MoveElementDirective,
        DiableAutofillDirective,
        SpinnerDirective,
        DayRelativeNameToStringDirective,
        TimeSlotToStringDirective,
        TimeSlotToStringPipe,
        AnchorScrollHighlightDirective,
        TabOrderDirective,
        DynamicTabIndexDirective,
        MoveFocusToNextAllowedElementDirective
    ],
    imports     : [],
    exports     : [
        FuseIfOnDomDirective,
        FuseInnerScrollDirective,
        FuseMatSidenavHelperDirective,
        FuseMatSidenavTogglerDirective,
        FusePerfectScrollbarDirective,
        DynamicComponentDirective,
        MoveElementDirective,
        SpinnerDirective,
        DiableAutofillDirective,
        DayRelativeNameToStringDirective,
        TimeSlotToStringDirective,
        TimeSlotToStringPipe,
        AnchorScrollHighlightDirective,
        TabOrderDirective,
        DynamicTabIndexDirective,
        MoveFocusToNextAllowedElementDirective
    ]
})
export class FuseDirectivesModule
{
}
